import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateNoVarsRead, TemplateService } from '@clients/api';

@Component({
  selector: 'ai-text-dialog',
  templateUrl: './ai-text-dialog.component.html',
  styleUrls: ['./ai-text-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiTextDialogComponent {
  formGroup: FormGroup;
  constructor(private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: string, public dialogRef: MatDialogRef<AiTextDialogComponent>) {
    this.formGroup = this.formBuilder.group({
      text: this.formBuilder.control(data),
    });
  }

  closeDialog(confirm: boolean) {
    if(confirm) {
      this.dialogRef.close(this.formGroup.get('text')?.value);
    }else{
      this.dialogRef.close();
    }
  }
}
