import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule } from '@clients/api';
import { environment } from '../environments/environment';
import {
  DeviceModule,
  ENVIRONMENT,
  FirebaseModule,
  FormModule,
  SentryModule,
  TranslationModule,
  VersionModule,
} from '@clients/helper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { ROUTES } from './app.routes';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserComponent } from './user/user.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { TemplateListComponent } from './home/template-list/template-list.component';
import { TemplateCardComponent } from './home/template-card/template-card.component';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { NewTemplateCardComponent } from './home/new-template-card/new-template-card.component';
import { LoginRequiredComponent } from './home/login-required/login-required.component';
import { LanguageModule, LoadingModule, QuillModule } from '@clients/shared';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LicenseExipresSoonComponent } from './home/license-exipres-soon/license-exipres-soon.component';
import { LicenseExipredComponent } from './home/license-exipred/license-exipred.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipe } from './home/pipes/filter.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatSelectModule } from '@angular/material/select';
import { RecommendComponent } from './home/recommend/recommend.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AiTextDialogModule } from '@clients/shared';

registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UserComponent,
    TemplateListComponent,
    TemplateCardComponent,
    NewTemplateCardComponent,
    LoginRequiredComponent,
    LicenseExipresSoonComponent,
    LicenseExipredComponent,
    FilterPipe,
    RecommendComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    RouterModule.forRoot(ROUTES),
    DragDropModule,
    MatToolbarModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    SentryModule.forRoot(environment),
    ApiModule.forRoot(),
    VersionModule.forRoot(),
    DeviceModule.forRoot(),
    FirebaseModule.forRoot(environment),
    HttpClientModule,
    TranslationModule.forRoot(),
    LanguageModule,
    LoadingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    FormModule,
    QuillModule.forRoot(),
    AiTextDialogModule
  ],
  providers: [
    { provide: ENVIRONMENT, useValue: environment },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: MAT_DATE_LOCALE, useValue: 'de' },
  ],
  bootstrap: [AppComponent],
  entryComponents: [LoginRequiredComponent],
})
export class AppModule {}
