import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VarPlaceholderComponent } from './var-placeholder/var-placeholder.component';
import { VarPlaceholderData } from './var-placeholder/var-placeholder.data';
import { VarPlaceholderService } from './var-placeholder/var-placeholder.service';
import { QuillEditorComponent, QuillViewComponent } from 'ngx-quill';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VarPlaceholderBlot } from './var-placeholder/var-placeholder.blot';
import { RangeStatic } from 'quill';
import {
  VarTemplate,
  WhoAmI,
  WhoAmIService
} from '@clients/api';
import { QUILL_MODULES } from './consts';

@UntilDestroy()
@Component({
  selector: 'shared-quill',
  templateUrl: './quill.component.html',
  styleUrls: ['./quill.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuillComponent implements OnInit, OnDestroy {
  @ViewChild(QuillEditorComponent) quillEditorComponent?: QuillEditorComponent;
  @ViewChild(QuillViewComponent) quillViewComponent?: QuillViewComponent;

  @Input() readonly = false;
  @Input() hideRephraseButton = false;
  @Input() rephraseLoading = false;
  @Input() readonlyContent?: string;
  @Input() controlName?: string;
  @Input() formGroup?: FormGroup;
  @Input() set hide(hide: boolean) {
    if (this.wizardDialogRef) {
      if (hide) {
        this.wizardDialogRef.addPanelClass('hidden');
      } else {
        this.wizardDialogRef.removePanelClass('hidden');
      }
    }
  }

  @Output() openTemplate: EventEmitter<number> = new EventEmitter<number>();
  @Output() rephraseText: EventEmitter<string> = new EventEmitter<string>();

  quillModules = QUILL_MODULES;
  varTypes: VarTemplate.TypeEnum[] = [
    VarTemplate.TypeEnum.Date,
    VarTemplate.TypeEnum.Text,
    VarTemplate.TypeEnum.Template,
  ];
  placeholderIconIdPrefix = VarPlaceholderBlot.iconIdPrefix;

  private whoAmI?: WhoAmI;
  private wizardDialogRef?: MatDialogRef<
    VarPlaceholderComponent,
    VarPlaceholderData | null
  >;

  constructor(
    private dialog: MatDialog,
    private varPlaceholderService: VarPlaceholderService,
    private whoAmIService: WhoAmIService,
  ) {}

  ngOnInit(): void {
    this.whoAmIService.whoAmI$
      .pipe(untilDestroyed(this))
      .subscribe((whoAmI?: WhoAmI) => {
        this.whoAmI = whoAmI;
      });

    this.varPlaceholderService.events$
      .pipe(untilDestroyed(this))
      .subscribe(
        (obj: { blot: VarPlaceholderBlot; data: VarPlaceholderData }) => {
          if (this.quillEditorComponent?.quillEditor.hasFocus()) {
            this.insertTemplate(obj.data.type, obj.data, obj.blot);
          }
        }
      );
  }

  ngOnDestroy() {
    if (this.wizardDialogRef) {
      this.wizardDialogRef.close(null);
    }
  }

  insertTemplate(
    type: VarTemplate.TypeEnum,
    currentVar?: VarPlaceholderData,
    blot?: VarPlaceholderBlot
  ) {
    if (this.wizardDialogRef) {
      return;
    }

    const selection: RangeStatic = this.varPlaceholderService.getIndex(
      this.quillEditorComponent?.quillEditor
    ) ?? {
      index: this.quillEditorComponent?.quillEditor.getLength() ?? 0,
      length: 0,
    };
    this.wizardDialogRef = this.dialog.open<VarPlaceholderComponent>(
      VarPlaceholderComponent,
      {
        minWidth: '60vw',
        hasBackdrop: false,
        disableClose: true,
        data: {
          type: type,
          current: currentVar || null,
          openTemplate: this.openTemplate,
          enableTemplateEdit: !(this.hideRephraseButton ?? true),
          whoAmI: this.whoAmI,
        },
      }
    );
    this.wizardDialogRef
      .afterClosed()
      .subscribe((result: VarPlaceholderData | string | null | undefined) => {
        delete this.wizardDialogRef;

        if (!result) {
          this.quillEditorComponent?.quillEditor.setSelection(selection);
          return;
        }
        if (typeof result === 'string' && result === 'delete') {
          if (blot) {
            blot.remove();
          }
          return;
        }

        if (blot) {
          blot.format(VarPlaceholderBlot.updateFormatKey, result);
        } else {
          this.varPlaceholderService.insertEmbed(
            result as VarPlaceholderData,
            selection,
            this.quillEditorComponent?.quillEditor
          );
        }
      });
  }

  getText(): string | undefined {
    if (this.readonly) {
      return this.quillViewComponent?.quillEditor.getText();
    } else {
      return this.quillEditorComponent?.quillEditor.getText();
    }
  }
}
