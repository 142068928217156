import {
  NgModule,
  ModuleWithProviders,
  SkipSelf,
  Optional,
} from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { GenerationControllerService } from './api/generationController.service';
import { GuestNoteControllerService } from './api/guestNoteController.service';
import { HealthControllerService } from './api/healthController.service';
import { HtmlUpdateControllerService } from './api/htmlUpdateController.service';
import { ImageControllerService } from './api/imageController.service';
import { LanguageControllerService } from './api/languageController.service';
import { LoginControllerService } from './api/loginController.service';
import { MySettingsControllerService } from './api/mySettingsController.service';
import { RephraseControllerService } from './api/rephraseController.service';
import { SystemControllerService } from './api/systemController.service';
import { TemplateCategoryControllerService } from './api/templateCategoryController.service';
import { TemplateControllerService } from './api/templateController.service';
import { TextControllerService } from './api/textController.service';
import { TranslationControllerService } from './api/translationController.service';
import { UserControllerService } from './api/userController.service';
import { VarControllerService } from './api/varController.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [],
})
export class ApiModule {
  public static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.'
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
